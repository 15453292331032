document.addEventListener("DOMContentLoaded", function() {
  var label = document.querySelectorAll(".selLabel"),
    dropdownFilterList = document.querySelectorAll(".dropdown__select-item");


  [...label].map(element =>  {
    element.addEventListener('click', function (e) {
      var contextualDropdownList = element.parentElement;
      contextualDropdownList.classList.toggle('active');
    }, false);
  });

  [...dropdownFilterList].map(element =>  {
    element.addEventListener('click', function () {
      var currentListSelect = element.parentElement.parentElement,
        currentLabel = currentListSelect.querySelector('.selLabel');
        currentInput = currentListSelect.querySelector('input');

        currentLabel.innerHTML = element.textContent;
        currentInput.value = element.getAttribute('data-value');

        $('.dropdown__select').removeClass('active');
    }, false);
  });
});
