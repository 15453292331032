$("#fale-conosco").submit(function(e){
  e.preventDefault();

  var data = $(this).serializeArray().reduce(function(obj, item) {
    obj[item.name] = item.value;
    return obj;
  }, {});

  $('.btn').attr('disabled', true);
  $('.btn').text('Enviando...');

  var customHeaders = new Headers({
    'Accept':       'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  });

  var myInit = {
    method: 'POST',
    headers: customHeaders,
    mode: 'cors',
    body: JSON.stringify({
      name: data.name,
      telefone: data.tel,
      email: data.email,
      message: data.message,
      type: data.type
    })
  };

  fetch('/send_email', myInit)
  .then(function(response) {
    if(response.ok) {
      document.getElementById("fale-conosco").reset();
      $('.btn').attr('disabled', false);
      $('.btn').text('Enviar Mensagem');
      window.location.href = "/obrigado";
    } else {
      console.log('Network response was not ok.');
    }
    console.log(response);
  })
  .catch(function(error) {
    console.log('Erro ao enviar o contato verifique os dados: ' + error.message);
  });

  $('.btn').attr('disabled', false);
});

$("#form-empreendimento").submit(function(e){
  e.preventDefault();

  var data = $(this).serializeArray().reduce(function(obj, item) {
    obj[item.name] = item.value;
    return obj;
  }, {});

  $('.btn-submit').attr('disabled', true);
  $('.btn-submit').text('Enviando');

  var customHeaders = new Headers({
    'Accept':       'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  });

  var myInit = {
    method: 'POST',
    headers: customHeaders,
    mode: 'cors',
    body: JSON.stringify({
      name: data.name,
      telefone: data.tel,
      email: data.email,
      message: data.message,
      type: data.type,
      enterprise: data.enterprise
    })
  };

  fetch('/send_email', myInit)
    .then(function(response) {
      console.log(response.ok);
      if(response.ok) {

        $('.btn-submit').attr('disabled', false);
        $('.btn-submit').text('Enviar Mensagem');

        window.location.href = "/obrigado";
      } else {
        console.log('Network response was not ok.');
      }
      console.log(response);
    })
    .catch(function(error) {
      console.log('Erro ao enviar o contato verifique os dados: ' + error.message);
    });

    $('.btn-submit').attr('disabled', false);
});

$("#assistencia").submit(function (e) {
  e.preventDefault();

  var files = document.querySelector('.js-files').files;

  var data = $(this).serializeArray().reduce(function (obj, item) {
    obj[item.name] = item.value;
    return obj;
  }, {});

  $('.btn').attr('disabled', true);
  $('.btn').text('Enviando...');
  var message = $('.form-control__message').val()

  var customHeaders = new Headers({
    'Accept': 'application/json',
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  });
  var formData = new FormData();
  for (const file of files) {
    formData.append('files[]',file,file.name)
  }

  formData.append('name', data.name)
  formData.append('telefone', data.tel)
  formData.append('email', data.email)
  formData.append('mensagem', data.message)
  formData.append('garantia', data.garantia)

  var myInit = {
    method: 'POST',
    headers: customHeaders,
    mode: 'cors',
    contentType: false,
    processData: false,
    body: formData
  };
  fetch('/send_email/assistencia', myInit)
    .then(function (response) {
      if (response.ok) {
        $('.btn').attr('disabled', false);
        $('.btn').text('Enviar Mensagem');
        window.location.href = "/obrigado";
      } else {
        console.log('Network response was not ok.');
      }
      console.log(response);
    })
    .catch(function (error) {
      console.log('Erro ao enviar o contato verifique os dados: ' + error.message);
    });
  $('.btn').attr('disabled', false);
});
