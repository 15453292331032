$('.js-change-to-login').click(function (){
  $(".form-signup").removeClass('active');
  $(".form-login").addClass('active');
  $('.modal__access').removeClass('modal__access--big')
})

$('.show-password__btn').click(function (e){
  e.preventDefault();
  $(this).toggleClass('active')

  if($('.show-password__btn').hasClass('active')) {
    $('.js-show-password').attr("type","text")
  } else {
    $('.js-show-password').attr("type","password")

  }
})

$("#signup").submit(function(e){
  e.preventDefault();

  var data = $(this).serializeArray().reduce(function(obj, item) {
    obj[item.name] = item.value;
    return obj;
  }, {});

  var customHeaders = new Headers({
    'Accept':       'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  });

  var myInit = {
    method: 'POST',
    headers: customHeaders,
    mode: 'cors',
    body: JSON.stringify({
      name: data.name,
      email: data.email,
      password: data.password,
      password_confirmation: data.password_confirmation,
      tel: data.tel,
      creci: data.creci
    })
  };

  var btnSignup = document.querySelector('.btn-signup')


  btnSignup.disabled = true;
  btnSignup.innerHTML = 'Cadastrando...';

  // precisa tratar a response do servidor para verificar se o e-mail já está cadastrado

  fetch('/tabelas/actions/signup', myInit)
  .then(function(response) {
    if(response.ok) {
      document.getElementById("signup").reset();
      btnSignup.disabled = false;
      btnSignup.innerHTML = 'Cadastrar';
      window.location.href = "/aprovacao";

      btnSignup.disabled = false;
      btnSignup.innerHTML = 'Cadastrar';
    } else {
      btnSignup.disabled = false;
      btnSignup.innerHTML = 'Cadastrar';

      alert('E-mail já cadastrado, faça login ou redefina sua senha');
    }
  })
});


