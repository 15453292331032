// require('./bootstrap');
require('./dropdown-select');
require('./acesso-tabelas');
require('./forms');
require('./login');
require('./signup');
require('./reset-password');


document.addEventListener("DOMContentLoaded", function() {
  var btnMenu = document.querySelector(".navigation__handler-menu"),
    overlayMenu = document.querySelector(".overlay-menu"),
    navigation__close = document.querySelector(".navigation__close"),
    body = document.querySelector("body");

  toggleMenu(btnMenu);
  toggleMenu(overlayMenu);
  toggleMenu(navigation__close);

  function toggleMenu(item) {
    item.addEventListener('click', function () {
      body.classList.toggle('active-menu');
    }, false);
  }

  function showFullBuildingDescription() {
    var btnMore = document.querySelector('.show-more'),
        btnMoreText = document.querySelector('.show-more span'),
        buildingDescription = document.querySelector('.single__building-about-description'),
        mainImageBuilding = document.querySelector('.single__building-about-figure');

      if(btnMore) {
        btnMore.addEventListener('click', function (e) {
          e.preventDefault();
          if(buildingDescription.classList.contains('active')) {
            btnMoreText.textContent = 'Ver mais';
            buildingDescription.classList.remove('active');
            mainImageBuilding.classList.remove('active');
          } else {
            btnMoreText.textContent = 'Ver menos';
            buildingDescription.classList.add('active');
            mainImageBuilding.classList.add('active');
          }
        }, false);
      }
  }

  function handlerForceScrollItemsTags() {
    var buttonRight = document.getElementById('slideRight'),
        buttonLeft = document.getElementById('slideLeft');

    if(!buttonRight && !buttonLeft)
      return;

    containerTags =  $('.maps__tags-list');

    buttonRight.onclick = function () {
      containerTags.animate( { scrollLeft: '+=1000' }, 500)
    };

    buttonLeft.onclick = function () {
      containerTags.animate( { scrollLeft: '-=1000' }, 500)
    };

  }
  handlerForceScrollItemsTags();
  showFullBuildingDescription();
});




