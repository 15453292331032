$("#recuperar-senha").submit(function (e) {
  e.preventDefault();

  var data = $(this).serializeArray().reduce(function (obj, item) {
    obj[item.name] = item.value;
    return obj;
  }, {});


  var customHeaders = new Headers({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  });

  var myInit = {
    method: 'POST',
    headers: customHeaders,
    mode: 'cors',
    body: JSON.stringify({
      email: data.email,
      'user-type': 'imobiliaria'
    })
  };
  var btnRecovery = document.querySelector('.btn-recovery');
    btnRecovery.disabled = true;
    btnRecovery.innerHTML = 'Enviando...';

  fetch('/reset-password', myInit)
    .then(function (response) {
      if (response.ok) {
        alert('E-mail enviado');
        document.getElementById("recuperar-senha").reset();
        btnRecovery.disabled = false;
        btnRecovery.innerHTML = 'Recuperar senha';
      } else {
        btnRecovery.disabled = false;
      }
    }).then(function (response) {
      console.log(response);
    });

  btnRecovery.disabled = false;
});
